import "./App.css";
import CategoryPage from "./components/CategoryPage";
import HomePage from "./components/HomePage";
import GameDetails from "./components/GameDetails";
import { BrowserRouter as Router, Routes, Route, Navigate } from "react-router-dom";
import SearchBox from "./components/SearchBox";
import Login from "./components/Login";
import LoginHeader from "./components/LoginHeader";
import ExistingUserNotcharged from "./ExistingUserNotCharged";
import Userlogin from "./components/Userlogin";

function App() {
  const checkCookie = () => {
    // Replace this with your logic to check the existence of the "gameoffy" cookie
    const gameblitzCookie = document.cookie.includes("gameblitz");
    return gameblitzCookie;
  };
  return (
    <div>
      <Router>
        <Routes>
          {/* <Route path="/Home" element={<HomePage />} /> */}
          <Route path="/home" element={
           <HomePage /> } />

          <Route path="/home/login" element={<Login/>} />
          <Route path="/home/check" element={<Userlogin/>} />
          <Route path="/home/login-header" element={<LoginHeader/>} />
          <Route
            path="/home/not-charged"
            element={<ExistingUserNotcharged/>}
          />
          <Route path="/games/:gameid/:category" element={<GameDetails />} />
          <Route path="/category/:category" element={<CategoryPage />}/>
          <Route path="/search" element={<SearchBox/>} />
      
        </Routes>
      </Router>
    </div>
  );
}

export default App;
