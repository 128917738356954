import React, { useEffect, useState } from "react";
import axios from "axios";
import Spinner from "./Spinner"
import { Link } from "react-router-dom";
import { LiaEye } from "react-icons/lia";
const AllGames = () => {
  const [games, setGames] = useState([]);
  const [loading, setLoading] = useState(true);
  console.log("hakkskak", games);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get("/api/games");
        setGames(response.data);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching data:", error);
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  if (loading) {
    return(
      <div>
     <Spinner/>
      </div>
    )
  }
  const limitedGames = games.slice(0, 20);

  return (
    <div className="container mx-auto px-4 py-5">
      {limitedGames.map((category, index) => (
        <div key={index}>
          <div className="mx-auto flex items-center justify-center gap-10 px-2 py-5">
            <ul className="border-purple-800 hover:border-yellow-300 rounded-3xl bg-slate-400 group flex-1 -skew-x-[10deg] transform border">
              <li className="m-4 skew-x-[10deg] transform bg-transparent px-9 text-center first-letter:uppercase">
                <b className="block text-lg text-[#2e1065]">
                  {category.category}
                </b>
              </li>
            </ul>
          </div>
          <div className="lg:mx-6 lg:mt-8 grid lg:grid-cols-4 md:grid-cols-4 sm:grid-cols-3 grid-cols-2 gap-4 lg:gap-8">
            {category.games.slice(0, 4).map((game, item) => (
              <div
                key={game.gameid}
                className={`${game.category} group h-[200px] sm:h-[300px] overflow-hidden relative shadow-lg max-w-xs rounded-xl`}
              >
                <img
                  className="block group-hover:opacity-40 transition-opacity duration-700 h-full w-full sm:w-full  object-cover"
                  src={game.img6}
                  alt={game.gameurl}
                />
                <div className="absolute bg-black flex items-center group-hover:-top-0 group-hover:opacity-100 duration-700 top-full right-0 w-full opacity-0 h-1/2 transition-all">
                  <img
                    className="block group-hover:opacity-40 transition-opacity duration-700 h-full w-full object-fill"
                    src={game.img6}
                    alt={game.gameurl}
                  />
                </div>
                <div className="absolute  duration-700 bg-gradient-to-r from-yellow-300 to-slate-500 text-white block left-0 right-0 top-full text-base h-1/2 w-full opacity-50 transition-all group-hover:top-1/2 group-hover:opacity-100">
                  <div className="py-4 text-xs px-7">
                    <div className="text-xl font-bold">{game.gamename}</div>
                    <div className="whitespace-nowrap overflow-hidden overflow-ellipsis relative z-20">
                      <span className="uppercase text-slate-800 whitespace-nowrap text-xs md:text-sm">
                        Category:
                      </span>
                      <span className="whitespace-nowrap overflow-hidden text-zinc-900 font-semibold text-lg overflow-ellipsis relative z-20">
                        <a href={game.publisherlink}> {game.category}</a>
                      </span>
                    </div>
                  </div>
                  <div className="absolute left-0 pl-7 pt-1">
                    <Link
                      to={`/games/${game.id}/${game.category}`}
                      key={game.id}
                    >
                      <button className="px-4 text-base block text-white rounded-sm border-2 border-opacity-20 bg-violet-950 duration-700">
                        Play
                      </button>
                    </Link>
                  </div>
                </div>
              </div>
            ))}
            {category.games.length > 4 && ( // Check if there are more than 4 games in the category
              <div className=" flex ">
                <Link
                  to={`/category/${category.category}`}
                  className="text-blue-500 "
                >
                
            <div className="flex gap-3">    <div className="mt-0 text-yellow-300">View more</div>
                <div className="mt-2 text-white"><LiaEye size={15}/></div></div>
                
                </Link>
              </div>
            )}
          </div>
        </div>
      ))}
    </div>
  );
};

export default AllGames;
