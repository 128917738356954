// import React, { useState, useEffect } from "react";
// import { IoMdClose } from "react-icons/io";
// import { useParams } from "react-router-dom";
// import axios from "axios";
// import Spinner from "./Spinner";

// const GameDetails = () => {
//   const { gameid, category } = useParams();
//   const [games, setGames] = useState([]);
//   const [loading, setLoading] = useState(true);

//   useEffect(() => {
//     const fetchGames = async () => {
//       try {
//         const response = await axios.get("/api/games");
//         const gamesData = response.data.filter((data)=>data?.category==category);

//         const gameIdInt = parseInt(gameid);
//         const game = gamesData[0].games.filter((game) => game.id == gameIdInt);
//         setGames(game);
//         setLoading(false);
//       } catch (error) {
//         console.error("Error fetching games:", error);
//         setLoading(false);
//       }
//     };

//     fetchGames();
//   }, [gameid, category]); // Trigger useEffect when gameid or category changes

//   console.log(games,'games')

//   if (loading) {
//     return (
//       <div>
//       <Spinner/>
//        </div>
//     );
//   }

//   return (
//     <div className="bg-black h-screen relative">
//       <div className="absolute top-4 left-4 p-2 text-red-600">
//         <IoMdClose size={30} onClick={() => window.history.back()} />
//       </div>
//       <div className="flex flex-wrap justify-center items-center ">
//         {games.length > 0 ? (
//           games.map((game) => (
//             <div key={game.id} className="w-full sm:w-1/2 md:w-1/3 p-4">
//             <div className="relative" >
//               <iframe
//                   className='w-full h-screen p-2'
//                   title={game.gamename}
//                   src={game.gameurl}
//               ></iframe>
//             </div>
//             <p className="text-white text-center mt-2">{game.name}</p>
//           </div>
          
//           ))
//         ) : (
//           <div className="text-white">No game found </div>
//         )}
//       </div>
//     </div>
//   );
// };

// export default GameDetails;


import React, { useState, useEffect } from "react";
import { IoMdClose } from "react-icons/io";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import Spinner from "./Spinner";
import Cookies from 'js-cookie'

const GameDetails = () => {
  const { gameid, category } = useParams();
  const [games, setGames] = useState([]);
  const [loading, setLoading] = useState(true);
const msisdn=Cookies.get('msisdn')
const navigate=useNavigate()
  useEffect(() => {
    const fetchGames = async () => {
      try {
        const response = await axios.get("/api/games");
        const gamesData = response.data.filter((data) => data?.category == category);

        const gameIdInt = parseInt(gameid);
        const game = gamesData[0].games.filter((game) => game.id === gameIdInt);
        setGames(game);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching games:", error);
        setLoading(false);
      }
    };

    fetchGames();
  }, [gameid, category]); 

  console.log(games, 'games');


  const ensureHttps = url => {
    if (url && url.startsWith("http://")) {
      return url.replace("http://", "https://");
    }
    return url;
  };

  if (loading) {
    return <div><Spinner /></div>;
  }


  const handleclick=()=>{
    navigate(`/home?msisdn=${msisdn}`)
  }

  return (
    <div className="bg-black h-screen relative">
      <div className="absolute top-4 left-4 p-2 text-red-600">
        {/* <IoMdClose size={30} onClick={() => window.history.back()} /> */}
        <IoMdClose size={30} onClick={() =>handleclick()} />

      </div>
      <div className="flex flex-wrap justify-center items-center">
        {games.length > 0 ? (
          games.map((game) => (
            <div key={game.id} className="w-full sm:w-1/2 md:w-1/3 p-4">
              <div className="relative">
                <iframe
                  className="w-full h-screen p-2"
                  title={game.gamename}
                  src={ensureHttps(game.gameurl)}
                ></iframe>
              </div>
              <p className="text-white text-center mt-2">{game.name}</p>
            </div>
          ))
        ) : (
          <div className="text-white">No game found</div>
        )}
      </div>
    </div>
  );
};

export default GameDetails;
