// import React, { useState } from "react";
// import { useNavigate, useLocation } from "react-router-dom";
// import Cookie from "js-cookie";
// import Lottie from "react-lottie";

// import lottieBall from "../assets/images/Animation - 1714563793470.json";

// const Login = () => {
//   const location = useLocation();
//   // const [phoneNumber, setPhoneNumber] = useState("");
//   const [error, setError] = useState("");
//   const navigate = useNavigate();
//   const searchParams = new URLSearchParams(location.search);
//   const initialMsisdn = searchParams.get("msisdn");
//   const [msisdn, setMsisdn] = useState(initialMsisdn || "");
//   console.log("msisdn ", msisdn);

//   const handleFormSubmit = (e) => {
//     e.preventDefault();

//     // Simulated login (replace this with actual login logic)
//     if (msisdn.trim() !== "") {
//       const userPhoneNo = msisdn; // For demonstration, use the entered number directly
//       Cookie.set("gameblitz", userPhoneNo, { expires: 5 });
//       navigate("/home");
//     } else {
//       setError("Please enter a valid phone number.");
//     }
//   };

//   const gameblitzCookie = Cookie.get("gameblitz");

//   if (gameblitzCookie) {
//     // Redirect to home if the userPhoneNumber cookie exists
//     navigate("/home");
//   }

//   return (
//     <div className="flex h-screen items-center justify-center p-10">
//       <div className="xl:w-1/2 rounded-2xl border border-blue-800 md:shadow-xl">
//         <div className="grid md:grid-cols-2 p-5">
//           <header className="w-300 mx-auto mb-5">
//             <Lottie
//               options={{ animationData: lottieBall }}
//               style={{ width: "90%", height: "auto" }}
//             />
//           </header>
//           <div className="flex items-center justify-center">
//             <form onSubmit={handleFormSubmit} className="w-full">
//               <h1 className="text-center font-extrabold uppercase text-rose-500">
//                 Enter your phone number
//               </h1>
//               <br />
//               <input
//                 type="text"
//                 className="mb-3 w-full rounded-2xl bg-zinc-100 outline-rose-400 px-5 py-3"
//                 placeholder="Enter your number"
//                 value={msisdn}
//                 onChange={(e) => setMsisdn(e.target.value)}
//               />
//               <button
//                 type="submit"
//                 className="mb-3 w-full rounded-2xl bg-gradient-to-r from-yellow-300 to-slate-500 px-5 py-3 font-semibold text-white"
//               >
//                 Submit
//               </button>
//               {error && <p className="text-red-500 text-sm">{error}</p>}
//             </form>
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default Login;






import React, { useEffect, useState } from "react";
import logo from "../assets/images/logo-gameblitz.png";
import { useNavigate, useLocation } from "react-router-dom";
import axios from "axios";
import Cookies from 'js-cookie'

const Login = () => {
  const location = useLocation();
  const [loading, setLoading] = useState(false);
  const [errorModalOpen, setErrorModalOpen] = useState(false);
  const [successModalOpen, setSuccessModalOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const searchParams = new URLSearchParams(location.search);
  const initialMsisdn = searchParams.get("msisdn");
  const [msisdn, setMsisdn] = useState(initialMsisdn || "");
  const [subscriptionType, setSubscriptionType] = useState("Daily");
  const navigate = useNavigate();




  // useEffect(() => {
  //   const cookies = document.cookie.split("; ");
  //   const gameblitzCookie = cookies.find((cookie) => cookie === "gameblitz=1");
  //   if (gameblitzCookie) {
  //     window.location.href = "/home";
  //   }
  // }, []);

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    

    axios.post(`/subscribe/user`, {
        msisdn,
        mact: "web",
        service_name: "gameblitz",
        pack: subscriptionType
      })
      .then(response => {
        setLoading(false);
        if (response.data === "Success") {
          setSuccessModalOpen(true);
          Cookies.set('msisdn',msisdn)
          setTimeout(() => {
            navigate(`/home?msisdn=${msisdn}`);
            setSuccessModalOpen(false);
          }, 2000);
        } else {
          setErrorModalOpen(true);
          setErrorMessage(response.data);
        }
      })
      .catch(error => {
        setLoading(false);
        setErrorModalOpen(true);
        setErrorMessage(error.message);
        navigate("/home/login");
      });
  };

  
  const getSuccessMessage = () => {
    switch (subscriptionType) {
      case "Daily":
        return " Cher abonné, vous avez souscris avec succès à 50F valable 24h.";
      case "3Day":
        return "Cher abonné, vous avez souscris avec succès à 100F valable 3 jours.";
      case "Weekly":
        return "Cher abonné, vous avez souscris avec succès à 150F valable 7 jours.";
      default:
        return "You have successfully subscribed.";
    }
  };

  return (
    <div className="bg-indigo-400 h-screen">
      {loading && (
        <div className="fixed top-0 left-0 w-full h-full flex items-center justify-center bg-gray-900 bg-opacity-50 z-50">
          <div className="animate-spin rounded-full h-20 w-20 border-t-2 border-b-2 border-gray-300"></div>
        </div>
      )}
      {errorModalOpen && (
        <div className="fixed inset-0 z-50 flex items-center justify-center overflow-x-hidden overflow-y-auto outline-none focus:outline-none">
          <div className="relative w-auto max-w-3xl mx-auto my-6">
            <div className="relative flex flex-col w-full bg-white border-0 rounded-lg shadow-lg outline-none focus:outline-none">
              <div className="flex items-start justify-between p-5 border-b border-solid border-gray-300 rounded-t">
                <h3 className="text-3xl font-semibold">Error</h3>
                <button
                  className="p-1 ml-auto bg-transparent border-0 text-black float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                  onClick={() => setErrorModalOpen(false)}
                >
                  <span className="text-black h-6 w-6 text-2xl block outline-none focus:outline-none">
                    ×
                  </span>
                </button>
              </div>
              <div className="relative p-6 flex-auto">
                <p className="my-4 text-gray-600 text-lg leading-relaxed">
                  {errorMessage}
                </p>
              </div>
            </div>
          </div>
        </div>
      )}
      {successModalOpen && (
        <div className="fixed inset-0 z-50 flex items-center justify-center overflow-x-hidden overflow-y-auto outline-none focus:outline-none">
          <div className="relative w-auto max-w-md mx-auto my-6">
            <div className="relative flex flex-col w-full bg-white border-0 rounded-lg shadow-lg outline-none focus:outline-none">
              <div className="flex items-start justify-between p-5 border-b border-solid border-gray-300 rounded-t">
                <h3 className="text-3xl font-semibold">Success!</h3>
              </div>
              <div className="relative p-6 flex-auto">
                <p className="my-4 text-gray-600 text-lg leading-relaxed">
                {getSuccessMessage()}
                </p>
              </div>
            </div>
          </div>
        </div>
      )}
     <div className="container mx-auto flex flex-col justify-center py-[60px]">
  <div className="mx-auto">
    <img className="rounded-t-lg h-20 w-30" src={logo} alt="GameBlitz Logo" />
  </div>
  <div className="flex justify-center py-3">
    <div className="max-w-sm bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700">
      <div className="p-5 shadow-xl shadow-purple-600">
        <form className="space-y-4" onSubmit={handleSubmit}>
          <h5 className="text-2xl text-[#6B57EF] font-bold text-center capitalize dark:text-white">
            Souscrivez et Profitez!
          </h5>
          {initialMsisdn ? (
            <div>
              <label htmlFor="number" className="block mb-2 text-sm font-medium text-gray-700 dark:text-grey-700">
                MSISDN
              </label>
              <input
                type="text"
                name="number"
                id="number"
                value={msisdn}
                readOnly
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white"
                required
              />
            </div>
          ) : (
            <div>
              <label htmlFor="number" className="block mb-2 text-sm font-medium text-gray-700 dark:text-grey-700">
                Veuillez entrer votre numéro
              </label>
              <input
                type="text"
                name="number"
                id="number"
                value={msisdn}
                onChange={(e) => setMsisdn(e.target.value)}
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white"
                required
              />
            </div>
          )}
          <div>
            <label htmlFor="subscriptionType" className="block mb-2 text-sm font-medium text-gray-700 dark:text-gray-700">
              Type d'abonnement
            </label>
            <select
              id="subscriptionType"
              value={subscriptionType}
              onChange={(e) => setSubscriptionType(e.target.value)}
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:text-white"
            >
              <option value="Daily">Jour :Souscrivez à partir de 50F</option>
              <option value="3Day">3 jours : Souscrivez à 100F</option>
              <option value="Weekly">Hebdo :Souscrivez à partir de 150F</option>
             
            </select>
          </div>
          <button
            type="submit"
            className="w-full text-white bg-gradient-to-br from-purple-600 to-blue-500 hover:bg-gradient-to-bl focus:ring-4 focus:outline-none focus:ring-blue-300 dark:focus:ring-blue-800 font-medium rounded-lg text-sm px-5 py-2.5 text-center me-2 mb-2"
          >
            Souscrivez maintenant
          </button>

          {!initialMsisdn && (
                <p className="text-sm text-gray-700 dark:text-grey-700 text-center mt-2">
                  Veuillez svp utiliser votre connexion mobile pour une
                  meilleure expérience
                </p>
              )}
        </form>
      </div>
    </div>
  </div>
</div>

    </div>
  );
};

export default Login;



