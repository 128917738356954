import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';  
import logo from '../assets/images/logo-gameblitz.png';
import Cookies from 'js-cookie'
const Userlogin = () => {
    const [msisdn, setMsisdn] = useState('');
    const navigate = useNavigate();  

    // const handleSubmit = async (event) => {
    //     event.preventDefault();
    //     const url = `/loginuser?msisdn=${msisdn}`;

    //     try {
    //         const response = await axios.get(url);
    //         console.log("status in login ",response.data);
            
    //         if (response.data.status === 1) {
    //            Cookies.set('msisdn', msisdn)
    //            setTimeout(() => {
    //                navigate('/home'); 
                
    //            }, 1000);
    //         } else if (response.data.status === 0) {
    //            setMsisdn('')
    //             navigate('/home/login'); 
    //         }
    //         else{
    //             setMsisdn('')
    //             navigate('/home/login'); 
    //         }
    //     } catch (error) {
    //         console.error('Error making the API call:', error);
    //     }
    // };


    const handleSubmit = async (event) => {
        event.preventDefault();

        // Check and prepend "229" if it does not start with it
        const formattedMsisdn = msisdn.startsWith('229') ? msisdn : `229${msisdn}`;

        let data = {
            msisdn: formattedMsisdn,
            service: "gameblitz"
        };

        try {
            console.log("started", data)
            const response = await axios.post(`/loginuser`, data);
            console.log("msisdn", formattedMsisdn);
            console.log("status in login ", response.data);
           
            if (response.data.status == "1") {
               Cookies.set('msisdn', formattedMsisdn);
               setTimeout(() => {
                   navigate(`/home?msisdn=${formattedMsisdn}`); 
               }, 2000);
            } else {
               setMsisdn('');
               navigate('/home/check'); 
            }
        } catch (error) {
            console.error('Error making the API call:', error);
            navigate('/home/check'); 
        }
    };
    return (
        <div className="bg-indigo-400 flex justify-center h-screen">
            <div className='max-w-sm container items-center mx-auto flex-col justify-center py-[60px] px-5'>
                <div className='items-center text-center pb-2'>
                    <img src={logo} className='w-90 h-40' alt="Gameblitz Logo" />
                </div>
                <div className="w-full max-w-sm p-4 bg-white border shadow-purple-600 border-gray-200 rounded-lg shadow-xl sm:p-6 md:p-8 dark:bg-gray-800 dark:border-gray-700">
                    <form className="space-y-6" onSubmit={handleSubmit}>
                        <h5 className="text-2xl font-bold text-center text-gray-900 dark:text-white">Login to Gameblitz</h5>
                        <div>
                            <label htmlFor="msisdn" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Enter number</label>
                            <input
                                type="number"
                                name="msisdn"
                                id="msisdn"
                                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white"
                                placeholder="***********"
                                required
                                value={msisdn}
                                onChange={(e) => setMsisdn(e.target.value)}
                            />
                        </div>
                        <button type="submit" className="w-full text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">Login</button>
                    </form>
                </div>
            </div>
        </div>
    );
}

export default Userlogin;
